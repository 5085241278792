<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Active Mortgages Available
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>Active Mortgages Available</h3>
          <br>
          <p>Below is a list of mortgages which are currently available for offers. Click a mortgage to see more details and make an offer.</p>
          <br />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br /> -->
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="dealList"
            
            :items-per-page="-1"
            dense
          >
            <template v-slot:item.attributes.city="{ item }">
              <span style="text-transform: capitalize;">{{ item.attributes.city }}</span>  </template
            >
            <template v-slot:item.attributes.property_type="{ item }">
              <span style="text-transform: capitalize;">{{ item.attributes.property_type }}</span>  </template
            >
            <template v-slot:item.attributes.status="{ item }">
              <span style="text-transform: capitalize;">{{ item.attributes.status }}</span>  </template
            >
       
            <template v-slot:item.attributes.amount_requested="{ item }">
              {{ item.attributes.amount_requested | formatPrice }} </template
            >
               <template v-slot:item.attributes.loan_to_value="{ item }">
              {{ item.attributes.loan_to_value }}% </template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  data() {
    return {
      componentLoading: true,
      dealList: [],
    //   search: "",
      headers: [
        {
          text: "City",
          align: "start",
          filterable: true,
          value: "city",
          
        },
        {
          text: "Province",
          align: "start",
          filterable: true,
          value: "province",
        },
        {
          text: "Type",
          align: "start",
          filterable: true,
          value: "property_type",
        },

        {
          text: "LTV",
          value: "loan_to_value",
        },
        {
          text: "Requested",
          value: "amount_requested",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Name",
          value: "deal.public_name",
        },
      ],
    };
  },
  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/mortgagesAvailable/read/${value.id}`);
    },
  },
  filters: {
    formatPrice(value) {
    //   if (typeof value !== "number") {
    //     return value;
    //   }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },

    booleanString(x) {
      if (x == true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  mounted() {
   
    // console.log('route query', this.$route.query)

    if (this.$route.query.status == "all") {
      this.listType = "- All";
    } else if (this.$route.query.status == "active") {
      this.listType = "- Active";
    } else if (this.$route.query.status == "awarded") {
      this.listType = "- Awarded";
    } else if (this.$route.query.status == "funded") {
      this.listType = "- Funded";
    } else if (this.$route.query.status == "inactive") {
      this.listType = "- Inactive";
    }

    API()
      .get(
        // `api/deal-summaries/?filters[status][$eq]=active`
        // `api/deal-summaries/?populate[0]=deal&filters[status][$eq]=active`
        '/api/deal-summary/user_deal_summaries_available_list'
      )
      .then((res) => {
     
        console.log('res of the deal summary list')
        console.log(res)
        // this.dealList = res.data.data;
        this.dealList = res.data

        
        this.componentLoading = false;
      })
      .catch((e) => {
      
        console.log(e);
      });
  },
};
</script>

<style>
</style>